/* styles defined for resuable components goes here */

.tag-default {
  /* TAG ESSENTIAL STYLES */
  @apply inline-flex items-center  px-2 py-1 rounded-md leading-5 break-all;
}
/* menu start */
.menu-items {
  @apply absolute origin-top-right bg-background text-foreground border rounded-md shadow-elevate1 outline-none;
}
.menu-item {
  @apply flex justify-between w-full px-4 py-2 text-sm leading-5 text-left;
}
.menu-item-active {
  @apply bg-gray-100 dark:bg-black text-gray-900 dark:text-gray-300 cursor-pointer;
}
.menu-item-inactive {
  @apply text-gray-700 dark:text-gray-400;
}
.menu-button {
  @apply inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-700 dark:text-gray-300 transition duration-150 ease-in-out bg-background border rounded-md;
}
.menu-button:hover {
  @apply text-gray-800 dark:text-gray-100;
}
.menu-button:focus {
  @apply outline-none;
}
.menu-button:active {
  @apply bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-300;
}
/* menu end */

/* Table */

.table-row > th:last-child {
  @apply border-r-0;
}

.table-body > tr:last-child > td {
  @apply border-b-0;
}

/* loader start */
.loader {
  border: 3px solid rgb(182, 198, 230);
  border-top-color: rgb(79 70 229);
}
/* loader end */

/* listbox/combobox options headless/react uses [--anchor-max-height] as css var for max height  */
.list-option {
  @apply p-1 max-h-60 overflow-auto rounded-md bg-background text-foreground border ring-black focus:outline-none sm:text-sm animate-slideDown z-50 [--anchor-max-height:15rem];
}

.list-button {
  @apply bg-background text-foreground rounded-md border relative text-left cursor-pointer text-sm overflow-hidden;
}

/* SIDEPANEL */
.pullButtonLeft {
  @apply absolute 
          cursor-pointer 
          p-[0.25rem]
          rounded-tr-xl 
          rounded-br-xl 
          w-[30px]
          flex 
          justify-end 
          items-center 
          transition-all 
          duration-300 
          ease-in-out 
          z-50 
          border-r 
          border-t 
          border-b 
          left-0
          top-2
          backdrop-blur-sm;
}
.pullButtonLeft:hover {
  @apply w-[50px];
}
.pullButtonRight {
  @apply absolute 
          cursor-pointer 
          p-[0.25rem]
          rounded-tl-xl 
          rounded-bl-xl 
          w-[30px]
          flex 
          justify-start 
          items-center 
          transition-all 
          duration-300 
          ease-in-out 
          z-50
          border-l 
          border-t 
          border-b 
          
          right-0
          top-2
          backdrop-blur-sm;
}
.pullButtonRight:hover {
  @apply w-[50px];
}
/* ZIndex order: popover -> modal -> toast */
.Toastify div {
  z-index: 99999999;
}

/* 🚦 disable the toastify base styles */
.Toastify__toast {
  padding: 0 !important;
  box-shadow: none !important;
  background: transparent !important;
  border: none !important;
  margin: 0 !important;
}
.Toastify__toast-icon {
  display: none !important;
}

.progress {
  /* width: 20%; */
  height: 2px;
  background: linear-gradient(
    90deg,
    rgba(110, 159, 255, 0) 0%,
    var(--highlight) 80.75%,
    rgba(110, 159, 255, 0) 100%
  );
  transform: translateX(-100%);
  animation-delay: 100ms;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  will-change: transform;
  animation-name: progress;
}

@keyframes progress {
  0% {
    transform: translateX(-100%);
  }

  85%,
  100% {
    transform: translateX(20%);
  }
}

.mentions__input {
  @apply input !overflow-auto h-28;
}
.mentions__highlighter {
  @apply overflow-hidden h-28 py-2 px-3;
}

.mentions__suggestions {
  @apply rounded-md bg-background text-foreground border ring-black focus:outline-none sm:text-sm animate-slideDown z-50;
}

.mentions__suggestions__list {
  @apply overflow-auto max-h-60 bg-background;
}

.mentions__suggestions__item {
  @apply menu-item text-muted-foreground;
}

.mentions__suggestions__item--focused {
  @apply bg-muted;
}

/* Excalidraw */

#excalidraw .excalidraw,
div.excalidraw.excalidraw-modal-container {
  --color-primary: var(--primary);
  --default-bg-color: var(--background);
  --text-primary-color: var(--foreground);
  --color-surface-primary-container: var(--background);
  --button-hover-bg: var(--background);
  --lg-button-size: 2.125rem;
  --color-surface-low: var(--muted);
  --color-surface-high: var(--muted);
  --island-bg-color: var(--muted);
  --input-bg-color: var(--background);
  --input-border-color: var(--border);
  --input-hover-bg-color: var(--muted);
  --button-gray-1: var(--muted);
  --button-gray-2: var(--muted-foreground);
  --button-gray-3: var(--secondary);
  --button-special-active-bg-color: var(--highlight);
  --link-color: var(--highlight);
  --select-highlight-color: var(--highlight);
  --dialog-border-color: var(--border);
  --focus-highlight-color: var(--highlight);
  --color-brand-hover: var(--foreground);
  --color-primary-light: var(--muted);
  --color-surface-mid: var(--muted);
}

/* Hiding library button */
.excalidraw .layer-ui__wrapper .zen-mode-transition label[title="Library"] {
  display: none !important;
}
.excalidraw .Switch.toggled {
  background: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
}
/* Resetting our override for checkbox and radio */
.excalidraw .Switch [type="checkbox"],
.excalidraw .RadioGroup [type="radio"] {
  background-image: none;
  border-color: transparent !important;
  background-color: transparent !important;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  outline: none;
  color: transparent !important;
}
.excalidraw .Switch [type="checkbox"]:focus,
.excalidraw .RadioGroup [type="radio"]:checked {
  background-image: none;
  border-color: transparent !important;
  background-color: transparent !important;
  border-width: 0;
  background-position: center;
  background-repeat: no-repeat;
  outline: none;
  color: transparent !important;
  --tw-ring-offset-width: 0;
  --tw-ring-offset-color: transparent;
}
.ExcButton--size-medium {
  min-height: auto !important;
}
